import React from "react";
import { withStyles } from "@material-ui/core/styles";
import qs from "qs";

import ApiTour from "../../../API/ApiTour";
import ApiUser from "../../../API/ApiUser";
import { validateAccount } from "../../../utils/validateAccount";
import BarChartUtils from "../../../utils/barChart";
import { withTranslation } from "react-i18next";

import styles from "./accountStyles";
import AccountView from "./accountView";
import ApiPayments from "../../../API/ApiPayments";

class Account extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isAdmin: false,
      isAdminSet: false,
      showError: false,
      dialogWaringOpened: false,
      errorMessage: "",
      showSuccess: false,
      successMessage: "",
      accountLoaded: false,
      isDisableToursOpened: false,
      account: {
        firstName: "",
        lastName: "",
        company: "",
        email: "",
        phoneNumber: "",
        createdAt: "",
        streetAddress: "",
        city: "",
        zipCode: -1,
        siret: "",
        maxNbOfTours: null,
        maxZones: null,
        maxEnigmas: null,
        role: null,
        secretCode: "",
        codeAmin: "",
        stripeCustomerId: null,
        setDisabledToursAt: null,
      },
      analyticsFromApi: null,
      analytics: { datasets: [] },
      monthOffset: 999,
      tourNames: [],
      isAnalyticDatasReady: false,
    };

    this.submitForm = this.submitForm.bind(this);
    this.updateForm = this.updateForm.bind(this);
    this.updateMonthOffset = this.updateMonthOffset.bind(this);
    this.onCancelSubscription = this.onCancelSubscription.bind(this);
    this.getCustomerPortalSession = this.getCustomerPortalSession.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
    this.openDialog = this.openDialog.bind(this);
    this.openDeactivatedTours = this.openDeactivatedTours.bind(this);
    this.isButtonDeactivatedToursDisabled =
      this.isButtonDeactivatedToursDisabled.bind(this);
    this.getDateButtonToursDisabledReactivate =
      this.getDateButtonToursDisabledReactivate.bind(this);
  }

  componentDidMount() {
    document.title = "Mon compte | Landing Zone";

    this.setStateIsAdmin();
    this.getAccount();
    this.getSessionStatus();
    this.onPlanUpdated();
    this.getAnalytics();
  }

  openDeactivatedTours(value) {
    this.setState(
      (prevState) => ({
        ...prevState,
        isDisableToursOpened: false,
      }),
      () => {
        this.setState((prevState) => ({
          ...prevState,
          isDisableToursOpened: value,
        }));
      }
    );
  }

  closeDialog() {
    this.setState((prevState) => ({
      ...prevState,
      dialogWaringOpened: false,
    }));
  }

  openDialog() {
    this.setState((prevState) => ({
      ...prevState,
      dialogWaringOpened: true,
    }));
  }

  async onPlanUpdated() {
    const { t } = this.props;

    let planUpdated = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    }).planUpdated;

    if (planUpdated) {
      // Temps pour la webhook de passer si ce n'est pas déjà fais
      await new Promise((resolve) => setTimeout(resolve, 2000));
      this.getAccount();
      this.setState((prevState) => ({
        ...prevState,
        showError: false,
        showSuccess: true,
        successMessage: t("account_view.success.account_updated"),
        dialogWaringOpened: false,
      }));
    }
  }

  getPriceFromType(type) {
    switch (type) {
      case "decouverteMonth":
        return 69;
      case "aventureMonth":
        return 89;
      case "expertMonth":
        return 159;
      case "decouverteEngagement":
        return 299;
      case "aventureEngagement":
        return 399;
      case "expertEngagement":
        return 699;
      default:
        return 0;
    }
  }

  async sha256(message) {
    // encoder la chaîne en UTF-8
    const msgBuffer = new TextEncoder().encode(message);

    // hash du message avec SHA-256
    const hashBuffer = await crypto.subtle.digest("SHA-256", msgBuffer);

    // convertir l'ArrayBuffer en tableau d'octets
    const hashArray = Array.from(new Uint8Array(hashBuffer));

    // convertir les octets en chaîne hexadécimale
    const hashHex = hashArray
      .map((b) => b.toString(16).padStart(2, "0"))
      .join("");
    return hashHex;
  }

  async getSessionStatus() {
    const { t } = this.props;

    let sessionId = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    }).stripe_session_id;

    if (sessionId) {
      const resStatus = await ApiPayments.getSessionStatus(sessionId);
      console.log("resStatus ", resStatus);
      if (resStatus.status == 200) {
        if (resStatus.data.status === "complete") {
          // Evenement facebook
          fbq("track", "Purchase", {
            value: this.getPriceFromType(resStatus.data.subscriptionType),
            currency: "EUR",
            user_data: {
              em: this.sha256(resStatus.data.email),
              fn: this.sha256(resStatus.data.firstname),
              ln: this.sha256(resStatus.data.lastname),
            },
          });

          // Temps pour la webhook de passer si ce n'est pas déjà fais
          await new Promise((resolve) => setTimeout(resolve, 2000));
          this.getAccount();
          this.setState((prevState) => ({
            ...prevState,
            showError: false,
            showSuccess: true,
            successMessage: t("account_view.success.subscription_updated"),
            dialogWaringOpened: false,
          }));
        } else {
          this.setState((prevState) => ({
            ...prevState,
            showError: true,
            showSuccess: false,
            errorMessage: t("account_view.success.payment_failed"),
            dialogWaringOpened: false,
          }));
        }
      }
    }
  }

  async onCancelSubscription() {
    const res = await ApiPayments.cancelSubscription();
    const { t } = this.props;

    if (res.status == 200) {
      this.setState((prevState) => ({
        ...prevState,
        showError: false,
        showSuccess: true,
        successMessage: t("account_view.success.subscription_cancelled"),
        dialogWaringOpened: false,
      }));
    }
  }

  async getCustomerPortalSession() {
    const res = await ApiPayments.getCustomerPortalSession();

    if (res.status == 200) {
      window.location.href = res.data.redirectTo;
    }
  }

  async updateMonthOffset(event) {
    let target = event.target;
    let value = target.value;

    let analyticsDatasFormatted = await BarChartUtils.formatDatasChart(
      this.state.analyticsFromApi,
      this.state.tourNames,
      value
    );

    this.setState((prevState) => ({
      ...prevState,
      analytics: analyticsDatasFormatted,
      monthOffset: value,
    }));
  }

  async getAnalytics() {
    let analytics = await ApiUser.getAnalytics();

    if (analytics.status != 200 || !analytics.data) {
      return;
    }

    let analyticsDatas = [];
    let tourNames = [];
    const tours = await ApiTour.getTours();

    for (var i in analytics.data) {
      let tour = tours.data.find((tour) => tour.id == analytics.data[i].TourId);
      let tourName = "";
      if (tour) {
        tourName = tour.title;
      }
      if (!tourNames.includes(tourName) && tourName != "") {
        tourNames.push(tourName);
      }

      analyticsDatas.push({
        tourName: tourName,
        id: analytics.data[i].id,
        createdAt: analytics.data[i].created_at,
        score: analytics.data[i].score,
        distanceTraveled: analytics.data[i].distanceTraveled,
      });
    }
    let analyticsDatasFormatted = await BarChartUtils.formatDatasChart(
      analyticsDatas,
      tourNames,
      null
    );

    this.setState((prevState) => ({
      ...prevState,
      analyticsLoaded: true,
      analytics: analyticsDatasFormatted,
      tourNames: tourNames,
      analyticsFromApi: analyticsDatas,
      isAnalyticDatasReady: true,
    }));
  }

  async getAccount() {
    const { t } = this.props;

    ApiUser.getAccount().then((response) => {
      if (response.status != 200 || !response.data) {
        this.setState((prevState) => ({
          ...prevState,
          showError: true,
          showSuccess: false,
          errorMessage: t("account_view.error.unknown_error"),
        }));

        return;
      }
      let address = ["", "", ""];
      if (response.data.address) {
        address = response.data.address.split(";");
      }
      if (!address) {
        this.setState((prevState) => ({
          ...prevState,
          showError: true,
          showSuccess: false,
          errorMessage: t("account_view.error.unknown_error"),
        }));

        return;
      }
      if (address.length != 3) {
        address.push("");
        address.push("");
      }

      this.setState((prevState) => ({
        ...prevState,
        accountLoaded: true,
        account: {
          firstName: response.data.firstname,
          lastName: response.data.lastname,
          company: response.data.companyName,
          phoneNumber: response.data.phoneNumber,
          createdAt: response.data.createdAt,
          streetAddress: address[0],
          city: address[1],
          zipCode: Number(address[2]) | 0,
          siret: response.data.companySiret,
          email: response.data.email,
          maxNbOfTours: response.data.maxTours,
          maxZones: response.data.maxZones,
          maxEnigmas: response.data.maxEnigmas,
          role: response.data.role,
          secretCode: response.data.secretCodeIPad,
          adminCode: response.data.adminCode,
          stripeCustomerId: response.data.stripeCustomerId,
          setDisabledToursAt: response.data.setDisabledToursAt,
        },
      }));
    });
  }

  isButtonUpdateDeactivatedToursDisabled() {
    if (this.state.account.setDisabledToursAt) {
      const disabledToursDate = new Date(this.state.account.setDisabledToursAt);
      const now = new Date();
      const differenceInDays =
        (now - disabledToursDate) / (1000 * 60 * 60 * 24);

      // Si la différence est inférieure à 7 jours, désactiver le bouton
      return differenceInDays < 7;
    }
    return false;
  }

  async submitForm(event) {
    event.preventDefault();

    this.updateProfile();
  }

  async updateProfile() {
    const { t } = this.props;

    if (
      !this.state.account.firstName ||
      this.state.account.firstName == "" ||
      !this.state.account.lastName ||
      this.state.account.lastName == "" ||
      !this.state.account.company ||
      this.state.account.company == ""
    ) {
      this.setState((prevState) => ({
        ...prevState,
        showError: true,
        errorMessage: t("account_view.error.missing_fields"),
        showSuccess: false,
        dialogWaringOpened: false,
      }));

      return;
    }

    try {
      validateAccount(
        "parameternotsent@gmail.com",
        this.state.account.siret,
        this.state.account.phoneNumber,
        1
      );
    } catch (err) {
      this.setState((prevState) => ({
        ...prevState,
        showError: true,
        errorMessage: err.message,
        showSuccess: false,
        dialogWaringOpened: false,
      }));

      return;
    }

    let addr = null;
    if (
      this.state.account.streetAddress &&
      this.state.account.city &&
      this.state.account.zipCode
    ) {
      addr =
        this.state.account.streetAddress +
        ";" +
        this.state.account.city +
        ";" +
        this.state.account.zipCode;
    }
    let response = await ApiUser.updateAccount(
      this.state.account.firstName,
      this.state.account.lastName,
      this.state.account.company,
      addr,
      this.state.account.siret,
      this.state.account.phoneNumber
    );

    if (response.status == 200 && response.data) {
      this.setState((prevState) => ({
        ...prevState,
        showError: false,
        showSuccess: true,
        successMessage: t("account_view.success.account_updated"),
        dialogWaringOpened: false,
      }));
    } else {
      this.setState((prevState) => ({
        ...prevState,
        showError: true,
        errorMessage: t("account_view.error.unknown_error"),
        showSuccess: false,
        dialogWaringOpened: false,
      }));
    }
  }

  async setStateIsAdmin() {
    let response = await ApiUser.isAdmin();

    if (response.status == 200) {
      this.setState((prevState) => ({
        ...prevState,
        isAdmin: response.data.isAdmin,
      }));
    }
  }

  updateForm(event) {
    let target = event.target;
    let value = target.value;

    if (target.name == "zipCode") {
      value = Number(target.value);
    }

    this.setState((prevState) => ({
      ...prevState,
      account: {
        ...prevState.account,
        [target.name]: value,
      },
    }));
  }

  isButtonDeactivatedToursDisabled() {
    if (this.state.account.role === "NO_SUBSCRIBTION") {
      return true;
    }
    if (this.state.account.setDisabledToursAt) {
      const disabledToursDate = new Date(this.state.account.setDisabledToursAt);
      const now = new Date();
      const differenceInDays =
        (now - disabledToursDate) / (1000 * 60 * 60 * 24);

      // Si la différence est inférieure à 7 jours, désactiver le bouton
      return differenceInDays < 7;
    }
    return false;
  }

  getDateButtonToursDisabledReactivate() {
    if (this.state.account.setDisabledToursAt) {
      const currentDate = new Date(this.state.account.setDisabledToursAt);
      const newDate = new Date(currentDate);
      newDate.setDate(newDate.getDate() + 7);
      const day = String(newDate.getDate()).padStart(2, "0"); // Ajoute un zéro si nécessaire
      const month = String(newDate.getMonth() + 1).padStart(2, "0"); // Les mois sont indexés de 0
      const year = newDate.getFullYear();

      return `${day}/${month}/${year}`;
    }
    return null;
  }

  render() {
    const { classes } = this.props;

    return (
      <>
        <AccountView
          {...this.props}
          state={this.state}
          updateForm={this.updateForm}
          submitForm={this.submitForm}
          updateMonthOffset={this.updateMonthOffset}
          closeDialog={this.closeDialog}
          openDialog={this.openDialog}
          onCancelSubscription={this.onCancelSubscription}
          getCustomerPortalSession={this.getCustomerPortalSession}
          isButtonUpdateDeactivatedToursDisabled={
            this.isButtonUpdateDeactivatedToursDisabled
          }
          isDisableToursOpened={this.state.isDisableToursOpened}
          openDeactivatedTours={this.openDeactivatedTours}
          isButtonDeactivatedToursDisabled={
            this.isButtonDeactivatedToursDisabled
          }
          getDateButtonToursDisabledReactivate={
            this.getDateButtonToursDisabledReactivate
          }
        />
      </>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  withTranslation()(Account)
);
