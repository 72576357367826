import React from "react";
import Alert from "@material-ui/lab/Alert";

import DraggableList from "../../components/draggableList";
import DialogWarning from "../../components/dialogWarning";
import MainAppBar from "../../components/MainAppBar";
import { Box, Grid } from "@material-ui/core";
import ParcoursListComponent from "./components/parcoursList/parcoursListComponent";
import CodeListComponent from "./components/parcoursCodes/codeListComponent";
import NoSubscriptionBanner from "./components/noSubscriptionBanner";
import DisableToursPopover from "../../../utils/disableToursPopover";
import FloatingVideoLink from "../../components/floatingVideoLinks";
import { useTranslation } from "react-i18next";

const HomeView = (props) => {
  const { t } = useTranslation();

  return (
    <>
      <MainAppBar
        headerText={t("home.header_text")}
        actionText={t("home.action_text")}
        actionUrl="/parcours"
        showAdminButton={props.state.isAdmin}
        role={props.role}
      />
      <NoSubscriptionBanner
        role={props.role}
        redirectToSelectedPack={props.state.user?.redirectToSelectedPack}
        classes={props.classes}
      ></NoSubscriptionBanner>
      <FloatingVideoLink role={props.role}></FloatingVideoLink>

      <DisableToursPopover
        userOpenedPopover={props.state.isDisableToursOpened}
      />
      <Grid container>
        <Grid item xs={4}>
          <Box
            className={`${props.classes.pannel}`}
            sx={{ p: 2, mt: 2, ml: 1, mr: 1 }}
          >
            <ParcoursListComponent
              {...props}
              role={props.role}
              tourIdSelected={props.state.tourIdSelected}
            ></ParcoursListComponent>
          </Box>
        </Grid>
        <Grid item xs={8}>
          <Box
            className={`${props.classes.pannel}`}
            sx={{ p: 2, mt: 2, mr: 1 }}
          >
            <CodeListComponent
              {...props}
              role={props.role}
              tourId={props.state.tourIdSelected}
            ></CodeListComponent>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default HomeView;
